<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="开通类型">
        <el-select v-model="type" placeholder="选择开通类型" clearable>
          <el-option label="购买" value="buy"></el-option>
          <el-option label="系统赠送" value="system"></el-option>
          <el-option label="签到" value="sign"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
      <el-form-item v-permission="'addChatCardDay'">
        <el-button  type="success" @click="addShow = true">增加天数</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
     <template #user="{ row }">
        <div>
          <link-to :to="{ name: 'userDetails', params: { id: row.user_id } }"> {{ row.map.user.nick_name }}</link-to>
        </div>
      </template>

      <template #type="{ row }">
        <div>
          {{typeMap[row.source]}}
        </div>
      </template>
    </auto-table>

    <el-dialog title="增加天数" :visible.sync="addShow" width="50%">
      <el-form>
        <el-form-item label="用户" required>
          <user-select :userid.sync="userid"></user-select>
        </el-form-item>
        <el-form-item label="天数" required>
          <el-input type="number" placeholder="增加天数" v-model="form.day">
          </el-input>
        </el-form-item>

        <el-form-item label="说明" required>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="增加说明"
            v-model="form.remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitHandler">增加天数</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addShow = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
 
  data() {
    return {
      DataList: [],
      Option: [
        { name: "用户", value: "user" ,type: "custom"},
        { name: "开通类型", value: "type", type: "custom" },
        { name: "开始时间", value: "start_time" },
        { name: "结束时间", value: "end_time" },
        { name: "说明", value: "remark" },
      ],
      Total: 0,
      type: "buy",
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
      addShow: false,
      form: {},
      userid: "",
      typeMap:{
        buy:'购买',
        system:'系统赠送',
        sign:'签到'

      }
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    submitHandler() {
        if(!this.userid){
             this.$message("未选择用户")
                return
         }
         if(!this.form.day || this.form.day <= 0 ){
             this.$message("天数错误")
                return
         }
      this.$axios({
        url: "/user/memberRecord/addChatCardDay",
        method: "post",
        data: {
          user_id: this.userid,
          day: this.form.day,
          remark: this.form.remark,
          handler_id: this.adminInfo.id,
          handler_name: this.adminInfo.name,
          source: "system",
        },
      }).then((res) => {
        this.form ={}
        this.addShow = false
        this.getList(1);
      });
    },

    userDetail(row) {
      this.$router.push({ name: "userDetails", params: { id: row.user_id } });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/memberRecord/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          type:'chat_card',
          source: this.type || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>